// Variables
@import 'variables';

// font-awesome
@import url('https://fonts.googleapis.com/css?family=Lora|Open+Sans:400,400i,700,700i');
@import 'node_modules/font-awesome/scss/font-awesome';

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'node_modules/owl.carousel/src/scss/owl.carousel.scss';
// Theme
html
{
    width: 100%;
    height: 100%;
}
body
{
    font-family: 'Open Sans', sans-serif;

    width: 100%;
    height: 100%;

    letter-spacing: 2px;

    color: $grey-light;
}
// Normalize
.lora
{
    font-family: 'Lora', sans-serif;

    letter-spacing: 3px;
}
.container,
.container-fluid
{
    position: relative;
}
.transition
{
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}
.btn
{
    @extend .transition;
}
.strong
{
    font-weight: bold !important;
}
.inline-table
{
    display: inline-table;
}
.longtext
{
    overflow: hidden;

    max-width: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.list-style-none
{
    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}
.img-responsive
{
    margin: auto;
}
.background-image
{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
h2.page-title
{
    display: block;
    overflow: hidden;

    margin: 45px 0 35px 0;

    text-align: center;
    white-space: nowrap;
    letter-spacing: 5px;
    text-transform: uppercase;

    color: $grey-light;

    @extend .lora;
    span
    {
        position: relative;

        display: inline-block;
        &:after,
        &:before
        {
            position: absolute;
            top: 50%;

            width: 9999px;
            height: 1px;

            content: '';

            background: #827c7d;
        }
        &:after
        {
            left: 100%;

            margin-left: 15px;
        }
        &:before
        {
            right: 100%;

            margin-right: 15px;
        }
    }
}
// /Normalize

// Header
header
{
    height: 195px;

    background-color: #f2f2f2;
    background-image: url('images/bg_header.png');
    background-repeat: no-repeat;
    background-position: right top;
    .brand
    {
        margin-top: 20px;
    }
    #main-menu
    {
        display: inline-block;

        margin-top: 80px;
        margin-right: 80px;
        li
        {
            margin: 0 20px;
            a
            {
                font-size: 19px;

                padding: 15px 0;

                color: $grey-light;
                border-bottom: 1px solid transparent;

                @extend .transition;
                @extend .lora;
            }
            &.active,
            &:hover
            {
                a
                {
                    text-decoration: none;

                    border-color: $grey-light;
                }
            }
        }
    }
    #languages
    {
        margin-top: 10px;
        li
        {
            a
            {
                display: block;

                border: 2px solid transparent;
                border-radius: 50%;
                &.active,
                &:hover
                {
                    border-color: $gray-light;
                }
            }
        }
    }
}
// / Header

// Footer
footer
{
    background-color: #f2f2f2;
    #maps
    {
        height: 400px;
    }
    .container
    {
        padding: 20px 0;
        .social
        {
            li
            {
                a
                {
                    font-size: 42px;

                    color: $grey-light;
                }
            }
        }
        .menu
        {
            margin-bottom: 20px;
            ul
            {
                li
                {
                    margin: 0 20px;
                    a
                    {
                        font-size: 19px;

                        padding: 15px 0;

                        color: $grey-light;
                        border-bottom: 1px solid transparent;

                        @extend .transition;
                        @extend .lora;
                    }
                    &.active,
                    &:hover
                    {
                        a
                        {
                            text-decoration: none;

                            border-color: $grey-light;
                        }
                    }
                }
            }
        }
        .credits
        {
            margin-top: 20px;
            .odnet
            {
                margin-bottom: 20px;

                opacity: .8;
            }
        }
    }
}
// / Footer

// Content
.owl-carousel
{
    .owl-nav
    {
        .owl-prev,
        .owl-next
        {
            font-size: 52px;
            line-height: 100%;

            position: absolute;
            z-index: 100;
            top: 40%;

            color: #fff;

            @extend .transition;
        }
        .owl-prev:hover,
        .owl-next:hover
        {
            color: $grey;
        }
        .owl-prev
        {
            left: 50px;
        }
        .owl-next
        {
            right: 50px;
        }
    }
    .owl-dots
    {
        width: 100%;
        margin: 20px 0;

        text-align: center;
        .owl-dot
        {
            display: inline-block;

            margin: 0 10px;

            border-radius: 50%;
            span
            {
                display: block;

                width: 13px;
                height: 13px;

                border-radius: 50%;
                background-color: $grey-light;

                @extend .transition;
            }
            span:hover
            {
                background-color: $grey-light;
            }
        }
        .owl-dot.active
        {
            span
            {
                background-color: $grey;
            }
        }
    }
}

// home
#home
{
    #slider
    {
        .item
        {
            height: 400px;
        }
    }
    #about-home
    {
        font-size: 18px;

        padding: 40px 0;
    }
}
// /home

// categories
#category-list
{
    ul
    {
        text-align: center;
        li
        {
            height: 350px;
            margin-bottom: 40px;
            &.col-md-4
            {
                padding-right: 20px;
                padding-left: 20px;
            }
            a
            {
                display: block;

                height: 100%;

                text-decoration: none;
                &:hover
                {
                    h3
                    {
                        background-color: transparent;
                    }
                }
                h3
                {
                    position: relative;

                    height: 100%;

                    background-color: rgba(0,0,0,.4);

                    @extend .transition;
                    span
                    {
                        font-size: 28px;

                        position: absolute;
                        top: 45%;
                        left: 10%;

                        width: 80%;
                        padding: 10px;

                        letter-spacing: 5px;

                        color: #fff;
                        border: 1px solid #fff;

                        @extend .lora;
                        @extend .text-uppercase;
                    }
                }
            }
        }
    }
}
#categories
{
    #category-list
    {
        margin-bottom: 40px;
    }
}
// /categories

// about
#about
{
    .about
    {
        margin-bottom: 40px;
    }
}
// /about

// products
#products
{
    #category-list
    {
        margin-bottom: 40px;
        ul
        {
            li
            {
                a
                {
                    h3
                    {
                        span
                        {
                            font-size: 18px;
                            top: 30%;
                        }
                    }
                }
            }
        }
    }
}
// /products

// contact
#contact
{
    form
    {
        margin-bottom: 40px;
    }
}
// /contact

// error
// /error

/// / Content
@import 'app-responsive';
