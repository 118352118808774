// Responsive
@import 'variables';
.transition
{
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}
@media (max-width: $screen-sm)
{
    h1
    {
        font-size: $font-size-h1-sm;
    }
    h2
    {
        font-size: $font-size-h2-sm;
    }
    h3
    {
        font-size: $font-size-h3-sm;
    }
    h4
    {
        font-size: $font-size-h4-sm;
    }
    h5
    {
        font-size: $font-size-h5-sm;
    }
    h6
    {
        font-size: $font-size-h6-sm;
    }
    .text-sm-left
    {
        text-align: left;
    }
    .text-sm-right
    {
        text-align: right;
    }
    .text-sm-center
    {
        text-align: center;
    }
    .text-sm-justify
    {
        text-align: justify;
    }
    #content
    {
        padding-top: 0;
    }
    header
    {
        .navbar.navbar-default
        {
            padding-right: 10px;
            padding-left: 10px;
            .navbar-nav
            {
                &.account
                {
                    border-left: 0;
                }
            }
        }
    }
}
// /Responsive
